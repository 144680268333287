import PostAddIcon from '@mui/icons-material/PostAdd'; // Публикации
import PermMediaIcon from '@mui/icons-material/PermMedia'; // Медиатека
import SportsTennisIcon from '@mui/icons-material/SportsTennis'; // Турниры + Иконка игрока
import VerifyIcon from '@mui/icons-material/CheckCircleOutline'; // Модерация турниров
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // Продвижение
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'; // Пользователи
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Партнеры

import GroupAddIcon from '@mui/icons-material/GroupAdd'; // Работа с заявками
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Справочники
// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'; // Баннеры
import SportsIcon from '@mui/icons-material/Sports'; // Иконка судьи
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // Иконка админа
import FaceIcon from '@mui/icons-material/Face'; // Иконка директора
import GavelIcon from '@mui/icons-material/Gavel'; // Иконка модератора
import PermIdentityIcon from '@mui/icons-material/PermIdentity'; // Иконка посетителя
import SuccessIcon from '@mui/icons-material/Check'; // Заявка одобрена
import CanceledIcon from '@mui/icons-material/Block'; // Заявка отклонена
import ReviewIcon from '@mui/icons-material/Visibility'; // Заявка на рассмотрении
import Filter1Icon from '@mui/icons-material/Filter1'; // Большая публикация
import Filter2Icon from '@mui/icons-material/Filter2'; // Средняя публикация
import Filter3Icon from '@mui/icons-material/Filter3'; // Маленькая публикация
import DraftIcon from '@mui/icons-material/BorderColor'; // Публикация - черновик
import VeteranIcon from '@mui/icons-material/Star'; // Иконка ветерана
import Pro from '@mui/icons-material/StarHalf'; // Иконка профи
import SemiPro from '@mui/icons-material/StarBorder'; // Иконка полупрофи
import FanIcon from '@mui/icons-material/SentimentSatisfiedAlt'; // Иконка любителя
import DoneIcon from '@mui/icons-material/Done'; // Иконка модерации продвижений
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';

import { v4 as uuid } from 'uuid';
import {
    purple,
    green,
    indigo,
    orange,
    red,
    teal,
    cyan,
    deepOrange,
    blueGrey,
    pink,
    lightGreen,
    yellow
} from '@mui/material/colors';
import OrderedList from '../assets/content-previews/Ordered-list.jpg'; // Превью нумерованного списка публикации
import ImportantText from '../assets/content-previews/Important-text.jpg'; // Превью важного
import Text from '../assets/content-previews/Text.jpg'; // Превью обычного текста
// import Instagram from '../src/assets/content-previews/Instagram.jpg'; // Превью инстаграма
import Quote from '../assets/content-previews/Quote.jpg'; // Превью цитаты
import Tournament from '../assets/content-previews/Tournament-table.jpg'; // Превью турнирной таблицы
import Photoalbum from '../assets/content-previews/Photoalbum.jpg'; // Превью фотоальбома
import Image from '../assets/content-previews/Image.jpg';

// const host = process.env.REACT_APP_API || 'http://192.168.1.24:8000/api/v1';
// const host = process.env.REACT_APP_API || 'http://172.20.10.11:8000/api/v1';
const host = process.env.REACT_APP_API || 'https://dev-amatour.ru/api/v1';
const port = process.env.PORT || 8080;
// const service = process.env.REACT_APP_MEDIA_LINK || 'http://192.168.1.24:8000';
// const service = process.env.REACT_APP_MEDIA_LINK || 'http://172.20.10.11:8000';
const service = process.env.REACT_APP_MEDIA_LINK || 'https://dev-amatour.ru';

const IS_PROD = process.env.REACT_APP_ENV === 'prod';
const IS_DEV = !IS_PROD;

export { IS_DEV, IS_PROD };

export default {
    server: {
        host,
        port,
        service,
        appId: 'amatour-admin',
        serviceHostPublic: `${host}`,
        serviceHost: `${host}/admin`, // админские ручки, юзаем serviceHost
        api: {
            // TODO: back
            files: {
                uri: '/files/'
            },
            tournaments: {
                uri: '/tournaments/'
            },
            exportTournaments: {
                uri: '/export/tournaments/'
            },
            exportTournamentsPlayers: (id) => `/export/tournaments/${id}/players`,
            tournamentsTags: {
                uri: '/tournaments/tags/'
            },
            tournamentsCount: {
                uri: '/tournaments/count/'
            },
            tournamentsMedia: {
                uri: '/SetTournamentMedia/'
            },
            promocodes: {
                uri: '/promocodes/'
            },
            checkPromocodes: {
                uri: '/promocodes/check/'
            },
            allSlots: {
                uri: '/slots/'
            },
            importantTournaments: {
                uri: '/important-tournaments/',
                resource: '/importantTournaments'
            },
            playersList: {
                uri: '/players/'
            },
            tournamentTable: (id) => `/tournaments/${id}/tables/`,
            signin: {
                uri: '/admin-login/'
            },
            oauth: {
                uri: '/signup/oauth/'
            },
            confirmEmail: {
                uri: '/signup/email-confirm/'
            },
            confirmPassword: {
                uri: '/signup/password/'
            },
            sendPairInvite: {
                uri: '/players/invite/'
            },
            getPlayerInfo: {
                uri: '/players/'
            },
            getCurrentUserInfo: {
                uri: '/profile/me/'
            },
            updateProfile: {
                uri: '/profile/'
            },
            sendPhone: {
                uri: '/signup/phone/'
            },
            confirmPhone: {
                uri: '/signup/phone-confirm/'
            },
            passwordReset: {
                uri: '/reset-password/'
            },
            confirmReset: {
                uri: '/reset-password/confirm/'
            },
            allRatings: {
                uri: '/ratings/'
            },
            publications: {
                uri: '/news/posts/'
            },
            publicationsCount: {
                uri: '/news/posts/count/'
            },
            globalSearch: {
                uri: '/search/'
            },
            photoAlbums: {
                uri: '/news/photoalbums/'
            },
            photoAlbumsCount: {
                uri: '/news/photoalbums/count/'
            },
            videos: {
                uri: '/news/videos/'
            },
            videosCount: {
                uri: '/news/videos/count/'
            },
            searchTags: {
                uri: '/search/top-queries/'
            },
            allUsers: {
                uri: '/users/'
            },
            updateRequest: {
                uri: '/tournaments/requests/'
            },
            albumPhotos: {
                uri: '/news/photos/'
            },
            uploadPhoto: {
                uri: '/news/photos/upload/'
            },
            promotions: {
                uri: '/announces/'
            },
            promotionsCount: {
                uri: '/announces/count/'
            },
            matches: {
                uri: '/matches/'
            },
            invitePlayer: {
                uri: '/players/invite/'
            },
            bulkSetChildren: {
                uri: '/BulkSetChildren'
            },
            adminTournaments: { uri: '/tournaments/' },
            tournamentTemplate: (tournamentId) => `/tournaments/${tournamentId}/template/`,
            tournamentPlayers: (tournamentId) => `/tournaments/${tournamentId}/players/`,
            tournamentPlayersCount: (tournamentId) => `/tournaments/${tournamentId}/players/count/`,
            playerInvites: (id) => `/players/${id}/invites/`,
            playerPersonalMeetings: (id) => `/players/${id}/personal-meetings/`,
            userTournaments: (userType, userId, tournamentType) =>
                `/${userType}/${userId}/tournaments/${tournamentType}/`,
            tournamentVideos: (id) => `/tournaments/${id}/videos/`,
            directorApplications: {
                uri: '/my/requests/'
            },
            refunds: {
                uri: '/refunds/'
            },
            directorApplicationsCount: {
                uri: '/my/requests/count/'
            },
            // directorApplications: (id) => `/directors/${id}/requests/`,
            addImageToTournament: (id) => `/tournaments/${id}/images/`,
            uploadCustomVideoSkin: (videoId) => `/news/videos/${videoId}/upload/`,
            uploadTournamentDoc: (tournamentId) => `/tournaments/${tournamentId}/docs/`,
            tournamentRules: (tournamentId) => `/tournaments/${tournamentId}/rules/`,
            tournamentStages: (tournamentId) => `/tournaments/${tournamentId}/stages/`,
            stage: { uri: '/stages/' },
            stageSubstages: (stageId) => `/stages/${stageId}/substages/`,
            tournamentSubstageMatch: (substageId) => `/matches/?substages=${substageId}`,
            // tournamentSubstageMatch: (substageId) => `/substages/${substageId}/matches/`,
            matchSets: (matchId) => `/matches/${matchId}/sets/`,
            setGames: (setId) => `/sets/${setId}/games/`,
            appointDirector: (playerId) => `/users/${playerId}/appoint_director/`,
            removeDirector: (playerId) => `/users/${playerId}/appoint_director/`,
            // appointJudge: (playerId) => `/players/${playerId}/appoint-judge/`,
            // removeJudge: (playerId) => `/players/${playerId}/remove-judge/`,
            announceImages: (announceId) => `/announces/${announceId}/images/`,
            publicationImages: (publicationId) => `/news/posts/${publicationId}/images/`,
            finishMatch: (matchId) => `/matches/${matchId}/live/end/`,
            startMatch: (matchId) => `/matches/${matchId}/live/start/`,
            mergeRequests: (tournamentId) => `/tournaments/${tournamentId}/requests/merge/`,
            determineStagePlaces: (tournamentId, stageId) =>
                `/tournaments/${tournamentId}/stages/${stageId}/PlacesDetermine/`,
            setStageWinners: (tournamentId, substageId) =>
                `/tournaments/${tournamentId}/substages/${substageId}/SetWinners/`,
            autoProcessStage: (tournamentId, stageId) =>
                `/tournaments/${tournamentId}/stages/${stageId}/Processing/`,
            autoProcessTournament: (tournamentId) =>
                `/tournaments/${tournamentId}/stages/Processing/`,

            // через квери tournament или stage или substage
            placesInfo: {
                uri: '/places/'
            },
            preliminaryPlayers: (tournamentId) =>
                `/tournaments/${tournamentId}/preliminary-players/`
        },
        apiPublic: {
            refreshToken: {
                uri: '/token/refresh/'
            },
            processPayment: {
                uri: '/my/payment/'
            },
            directors: {
                uri: '/directors/'
            },
            regions: {
                uri: '/references/regions/'
            },
            cities: {
                uri: '/references/cities/'
            },
            categoryGroups: {
                uri: '/references/categoryGroups/'
            },
            countries: {
                uri: '/references/countries/'
            },
            seasons: {
                uri: '/references/seasons/'
            },
            allTags: {
                uri: '/references/tags/'
            },
            tournamentCategories: {
                uri: '/references/tournament_categories/'
            },
            sportCategories: {
                uri: '/references/sports_categories/'
            },
            clubs: {
                uri: '/references/clubs/'
            },
            courtCoverages: {
                uri: '/references/court_coverages/'
            },
            allPartners: {
                uri: '/references/partners/'
            },
            tournamentCategoryPoints: (categoryId) =>
                `/references/tournament_categories/${categoryId}/points/`,
            getSubscriptionOptions: {
                uri: '/subscriptions/'
            }
        },
        errors: {
            wrongAuthCredentials: 'Неверный email или пароль',
            wrongAuthCredentialsRU: 'Указаны некорректные данные для входа',
            authError: 'Произошла ошибка. Невозможно войти.',
            noAccessRights: 'No access rights',
            existingRequest: 'Заявка уже существует',
            notFound: 'Страница не найдена.'
        }
    },
    routes: {
        HOME: '/',
        TUTORIALS: '/tutorials',
        DASHBOARD_HOME: '/dashboard',
        DASHBOARD_SECTION: '/dashboard/:section'
    },
    references: {
        captcha: '6Lc2ENQdAAAAAAIkHzSHt6ufR9Nf5Lqe83ZVlIE-',
        phoneMask: '+7 (###)-###-##-##',
        serverError: 'Ошибка! Что-то пошло не так',
        oauthRedirect: 'https://my.amatour.ru/',
        maxIntValue: 2147483647,
        fbAppId: 571714947182015,
        vkAppId: 7930470,
        okAppId: 512001055352,
        rowsPerPage: 8,
        drawerSmallWidth: 50,
        drawerFullWidth: 240,
        inviteLifeTime: 7, // убрали с фронта
        cdnPrefix: 'https://577522.selcdn.ru/amatour',
        firstPlayerKey: 'player',
        secondPlayerKey: 'player_2',
        maxPrizePoolValue: 2147483647,
        editTournamentSubmitButtonText: 'Сохранить',
        stageTypesWithSubstageCreation: ['single', 'double'],
        tournamentSystemsWithCustomMatchCreation: ['Круговая', 'Смешанная'],
        stageTypesWithCustomMatchCreation: ['playoff', 'free'],
        protocolRules:
            'Счет гейма пишется через прямой слеш «/». Сеты разделяются запятой с пробелами «, ». Пример: «2/6, 6/3, 5/6». При отказе игрока от игры пишется: «отк.» или «поб.», если отказался соперник.',
        allowedProtocolSymbols: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', ',', ' '],
        usersPageFilters: [
            {
                id: 'player',
                name: 'Игрок'
            },
            {
                id: 'director',
                name: 'Директор'
            },
            // {
            //     id: 'judge',
            //     name: 'Судья'
            // },
            {
                id: 'admin',
                name: 'Администратор'
            },
            {
                id: 'visitor',
                name: 'Посетитель'
            }
        ],
        requestStatusOptions: {
            approved: 'approved',
            draft: 'draft',
            rejected: 'rejected'
        },
        sexOptions: {
            male: 'Мужской',
            female: 'Женский'
        },
        partnerSelectionMethods: {
            self: 'self',
            director: 'director'
        },
        paymentStatus: {
            inProcess: 'In Process',
            approved: 'Approved',
            canceled: 'Canceled',
            declined: 'Declined',
            timeout: 'Timeout'
        },
        priceType: [
            {
                id: 'player',
                name: 'За игрока'
            },
            {
                id: 'pair',
                name: 'За пару'
            }
        ],
        getPlayerProfileLink: (playerId) => `${service}/players/${playerId}`,
        defaultAnnouncesCovers: [
            {
                image: '',
                placement: 'Главная (1520x288)',
                option: 'desktop',
                cropper: { width: 1520, height: 288 }
            },
            {
                image: '',
                placement: 'Главная (мини) (760x288)',
                option: 'desktop_half',
                cropper: { width: 760, height: 288 }
            },
            {
                image: '',
                placement: 'Ретина (2132x576)',
                option: 'retina',
                cropper: { width: 2132, height: 576 }
            },
            {
                image: '',
                placement: 'Сайдбар (300x220)',
                option: 'sidebar',
                cropper: { width: 300, height: 220 }
            },
            {
                image: '',
                placement: 'Бургер-меню (479x694)',
                option: 'humburger',
                cropper: { width: 479, height: 694 }
            },
            {
                image: '',
                placement: 'Мобильная версия (398x512)',
                option: 'mobile',
                cropper: { width: 398, height: 512 }
            }
        ],
        defaultAnnouncesCoversTemplate1: [
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_1520x288.jpg',
                placement: 'Главная (1520x288)',
                option: 'desktop',
                cropper: { width: 1520, height: 288 }
            },
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_760x288.jpg',
                placement: 'Главная (мини) (760x288)',
                option: 'desktop_half',
                cropper: { width: 760, height: 288 }
            },
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_2132x576_RETINA.jpg',
                placement: 'Ретина (2132x576)',
                option: 'retina',
                cropper: { width: 2132, height: 576 }
            },
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_300x220.jpg',
                placement: 'Сайдбар (300x220)',
                option: 'sidebar',
                cropper: { width: 300, height: 220 }
            },
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_479x694.jpg',
                placement: 'Бургер-меню (479x694)',
                option: 'humburger',
                cropper: { width: 479, height: 694 }
            },
            {
                image: 'https://577522.selcdn.ru/amatour/DEFAULT_ANNOUNCE_398x512.jpg',
                placement: 'Мобильная версия (398x512)',
                option: 'mobile',
                cropper: { width: 398, height: 512 }
            }
        ],
        announcesCroppers: {
            desktop: { width: 1520, height: 288 },
            desktop_half: { width: 760, height: 288 },
            retina: { width: 2132, height: 576 },
            sidebar: { width: 300, height: 220 },
            humburger: { width: 479, height: 694 },
            mobile: { width: 398, height: 512 }
        },
        announcesPlacements: {
            desktop: 'Главная (1520x288)',
            desktop_half: 'Главная (мини) (760x288)',
            retina: 'Ретина (2132x576)',
            sidebar: 'Сайдбар (300x220)',
            humburger: 'Бургер-меню (479x694)',
            mobile: 'Мобильная версия (398x512)'
        },
        pairWinnerOptions: [
            {
                id: 'request_1',
                name: 'Пара 1'
            },
            {
                id: 'request_2',
                name: 'Пара 2'
            }
        ],
        soloWinnerOptions: [
            {
                id: 'request_1',
                name: 'Игрок 1'
            },
            {
                id: 'request_2',
                name: 'Игрок 2'
            }
        ],
        decidingSetOptions: [
            {
                id: 'set',
                name: 'Сет'
            },
            {
                id: 'tiebreak',
                name: 'Тай-брейк'
            }
        ],
        conflictRuleOptions: [
            {
                id: '0',
                name: 'Разница выигранных/проигранных геймов'
            },
            {
                id: '1',
                name: 'Личные встречи между участниками, занимающими одну и ту же позицию'
            }
        ],
        olympicStage: 'playoff',
        groupStage: 'single',
        stagesTypes: {
            single: 'Групповой',
            // double: 'В два круга',
            playoff: 'Плей-офф',
            free: 'Свободный этап'
        },
        stagesTypesColors: {
            // single: 'primary',
            // double: 'primary',
            // playoff: 'primary',
            // free: 'primary'
            single: purple[500],
            double: teal[500],
            playoff: orange[500],
            free: lightGreen[500]
        },
        stageTypes: [
            {
                id: 'single',
                name: 'Групповой'
            },
            // {
            //     id: 'double',
            //     name: 'В два круга'
            // },
            {
                id: 'playoff',
                name: 'Плей-офф'
            }
        ],
        stageNameOptions: [
            {
                id: 'Предварительный турнир',
                name: 'Предварительный турнир'
            },
            {
                id: 'Основной турнир',
                name: 'Основной турнир'
            },
            {
                id: 'Дополнительный турнир',
                name: 'Дополнительный турнир'
            }
        ],
        substageNameOptions: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z'
        ].map((el) => ({ id: `Группа ${el}`, name: `Группа ${el}` })),
        subStageTypes: [
            {
                id: 'third_place',
                name: 'За третье место'
            },
            {
                id: 'final',
                name: 'Финал'
            },
            {
                id: 'semifinal',
                name: 'Полуфинал'
            },
            {
                id: 'other',
                name: 'Другое'
            }
        ],
        stageCategories: [
            {
                id: 'basic',
                name: 'Основная сетка'
            },
            // {
            //     id: 'loser_additional',
            //     name: 'Дополнительный турнир от первого этапа'
            // },
            {
                id: 'additional',
                name: 'Дополнительный турнир'
            }
        ],
        contentBlocksInitialValues: {
            importantText: {
                content: ''
            },
            text: {
                content: ''
            },
            orderedList: {
                title: '',
                content: []
            },
            quote: {
                content: '',
                author: ''
            },
            image: {
                content: ''
            },
            photoalbum: {
                id: ''
            },
            instagram: {
                link: ''
            },
            tournament: {
                id: ''
            }
        },
        contentBlocksByPublicationType: {
            big: [
                {
                    id: uuid(),
                    type: 'importantText',
                    description: 'Важный текст',
                    preview: ImportantText
                },
                {
                    id: uuid(),
                    type: 'text',
                    description: 'Обычный текст',
                    preview: Text
                },
                {
                    id: uuid(),
                    type: 'orderedList',
                    description: 'Список',
                    preview: OrderedList
                },
                {
                    id: uuid(),
                    type: 'quote',
                    description: 'Цитата',
                    preview: Quote
                },
                {
                    id: uuid(),
                    type: 'image',
                    description: 'Картинка',
                    preview: Image
                },
                {
                    id: uuid(),
                    type: 'photoalbum',
                    description: 'Фотоальбом',
                    preview: Photoalbum
                },
                // {
                //     id: uuid(),
                //     type: 'instagram',
                //     description: 'Пост из Instagram',
                //     preview: Instagram
                // },
                {
                    id: uuid(),
                    type: 'tournament',
                    description: 'Турнирная таблица',
                    preview: Tournament
                }
            ],
            medium: [
                {
                    id: uuid(),
                    type: 'importantText',
                    description: 'Важный текст',
                    preview: ImportantText
                },
                {
                    id: uuid(),
                    type: 'text',
                    description: 'Обычный текст',
                    preview: Text
                },
                {
                    id: uuid(),
                    type: 'image',
                    description: 'Картинка',
                    preview: Image
                },
                {
                    id: uuid(),
                    type: 'photoalbum',
                    description: 'Фотоальбом',
                    preview: Photoalbum
                },
                {
                    type: 'tournament',
                    description: 'Турнирная таблица',
                    preview: Tournament
                }
            ],
            small: [
                {
                    id: uuid(),
                    type: 'text',
                    description: 'Обычный текст',
                    preview: Text
                }
            ]
        },
        defaultTournamentsCovers: [
            {
                device_type: 'desktop',
                size: '1520x288',
                cropper: { width: 1520, height: 288 }
            },
            {
                device_type: 'cover',
                size: '330x150',
                cropper: { width: 330, height: 150 }
            },
            {
                device_type: 'tablet',
                size: '1066x288',
                cropper: { width: 1066, height: 288 }
            },
            {
                device_type: 'retina',
                size: '2132x576',
                cropper: { width: 2132, height: 576 }
            },
            {
                device_type: 'mobile',
                size: '398x512',
                cropper: { width: 398, height: 512 }
            },
            {
                device_type: 'announcement_desktop',
                size: 'Announcement Desktop 810х340',
                cropper: { width: 810, height: 340 }
            },
            {
                device_type: 'announcement_tablet',
                size: 'Announcement Tablet 1224х445',
                cropper: { width: 1224, height: 445 }
            },
            {
                device_type: 'announcement_mobile',
                size: 'Announcement Mobile 398x512',
                cropper: { width: 398, height: 512 }
            }
        ],
        tournamentCroppers: {
            desktop: { width: 1520, height: 288 },
            cover: { width: 330, height: 150 },
            tablet: { width: 1066, height: 288 },
            retina: { width: 2132, height: 576 },
            mobile: { width: 398, height: 512 },
            announcement_desktop: { width: 810, height: 340 },
            announcement_tablet: { width: 1224, height: 445 },
            announcement_mobile: { width: 398, height: 512 }
        },
        tournamentCoversSizes: {
            desktop: '1520x288',
            cover: '330x150',
            tablet: '1066x288',
            retina: '2132x576',
            mobile: '398x512',
            announcement_desktop: 'Announcement Desktop 810х340',
            announcement_tablet: 'Announcement Tablet 1224х445',
            announcement_mobile: 'Announcement Mobile 398x512'
        },
        publicationsTypes: {
            big: 'Большая',
            medium: 'Средняя',
            small: 'Маленькая'
        },
        publicationsTypesOptions: [
            {
                id: 'big',
                name: 'Большая'
            },
            {
                id: 'medium',
                name: 'Средняя'
            },
            {
                id: 'small',
                name: 'Маленькая'
            }
        ],
        publicationsTypesColors: {
            // big: purple[500],
            // medium: teal[500],
            // small: orange[500]
            big: 'primary',
            medium: 'primary',
            small: 'primary'
        },
        publicationsTypesIcons: {
            big: <Filter1Icon />,
            medium: <Filter2Icon />,
            small: <Filter3Icon />
        },
        publicationsStates: {
            draft: 'Черновик',
            published: 'Опубликована'
        },
        publicationsStatesIcons: {
            draft: <DraftIcon />,
            published: <SuccessIcon />
        },
        publicationsStatesColors: {
            draft: 'primary',
            // published: 'primary'
            published: green[500]
        },
        referencesHeadings: {
            sportCategories: 'Разряды',
            tournamentCategories: 'Категории турниров',
            courtCoverages: 'Покрытия кортов',
            clubs: 'Клубы',
            cities: 'Города',
            regions: 'Регионы',
            countries: 'Страны'
        },
        referencesInitialValues: {
            sportCategories: {
                name: '',
                is_pair: false
            },
            tournamentCategories: {
                name: '',
                note: '',
                points: [],
                service_fee: true,
                group: ''
            },
            cities: {
                name: '',
                region: ''
            },
            regions: {
                name: '',
                country: '',
                region_color: ''
            },
            clubs: {
                name: '',
                city: '',
                address: ''
            }
        },
        tournamentImportantVariants: [
            {
                id: 'golden',
                name: 'Золотая ракетка'
            },
            {
                id: 'santa_cup',
                name: 'Santa Cup'
            },
            {
                id: 'russian_open',
                name: 'Russian Open'
            },
            {
                id: 'summer_cup',
                name: 'Summer Cup'
            },
            {
                id: 'amatour_open',
                name: 'Amatour Open'
            }
        ],
        tournamentSystems: [
            {
                id: 'circle',
                name: 'Круговая'
            },
            {
                id: 'olimpic',
                name: 'Олимпийская'
            },
            {
                id: 'mixed',
                name: 'Смешанная'
            }
        ],
        currency: [
            {
                id: 'RUB',
                name: 'RUB'
            },
            {
                id: 'USD',
                name: 'USD'
            },
            {
                id: 'EUR',
                name: 'EUR'
            }
        ],
        roleOptions: [
            { id: 'admin', name: 'Администратор' },
            { id: 'director', name: 'Директор' },
            { id: 'player', name: 'Игрок' },
            { id: 'visitor', name: 'Посетитель' }
            // { id: 'judge', name: 'Судья' },
            // { id: 'moderator', name: 'Модератор' }
        ],
        statusOptions: [
            { name: 'Ветеран', id: 'veteran' },
            { name: 'Профи', id: 'pro' },
            { name: 'Полупрофи', id: 'semi-pro' },
            { name: 'Любитель', id: 'fan' }
        ],
        ntrp: [
            { name: '1.0', id: '1.0' },
            { name: '1.5', id: '1.5' },
            { name: '2.0', id: '2.0' },
            { name: '2.5', id: '2.5' },
            { name: '3.0', id: '3.0' },
            { name: '3.5', id: '3.5' },
            { name: '4.0', id: '4.0' },
            { name: '4.5', id: '4.5' },
            { name: '5.0', id: '5.0' },
            { name: '5.5', id: '5.5' },
            { name: '6.0–7.0', id: '6.0–7.0' }
        ],
        sideBarSections: {
            publications: 'Публикации',
            media: 'Медиатека',
            tournaments: 'Турниры',
            promotion: 'Продвижение',
            users: 'Пользователи',
            // partners: 'Партнеры',
            references: 'Справочники',
            tutorials: 'Помощь',
            applications: 'Работа с заявками',
            banners: 'Баннеры',
            'tournaments-approval': 'Модерация турниров',
            'promotions-approval': 'Модерация продвижений',
            ads: 'Рекламные баннеры',
            refunds: 'Заявки на возвраты'
        },
        sideBarGroups: {
            admin: [
                [
                    {
                        text: 'Публикации',
                        to: '/dashboard/publications',
                        icon: <PostAddIcon />
                    },
                    {
                        text: 'Медиатека',
                        to: '/dashboard/media',
                        icon: <PermMediaIcon />
                    },
                    {
                        text: 'Турниры',
                        to: '/dashboard/tournaments',
                        icon: <SportsTennisIcon />
                    },
                    {
                        text: 'Модерация турниров',
                        to: '/dashboard/tournaments-approval',
                        icon: <VerifyIcon />
                    },
                    {
                        text: 'Работа с заявками',
                        to: '/dashboard/applications',
                        icon: <GroupAddIcon />
                    }
                ],
                [
                    {
                        text: 'Продвижение',
                        to: '/dashboard/promotion',
                        icon: <TrendingUpIcon />
                    },
                    {
                        text: 'Модерация продвижений',
                        to: '/dashboard/promotions-approval',
                        icon: <DoneIcon />
                    },
                    {
                        text: 'Рекламные баннеры',
                        to: '/dashboard/ads',
                        icon: <ViewCarouselOutlinedIcon />
                    }
                ],
                [
                    {
                        text: 'Пользователи',
                        to: '/dashboard/users',
                        icon: <PeopleOutlineIcon />
                    },
                    {
                        text: 'Заявки на возвраты',
                        to: '/dashboard/refunds',
                        icon: <RequestQuoteOutlinedIcon />
                    }
                    // {
                    //     text: 'Партнеры',
                    //     link: '/dashboard/partners',
                    //     icon: <AttachMoneyIcon />
                    // }
                ],
                [
                    {
                        text: 'Справочники',
                        to: '/dashboard/references',
                        icon: <HelpOutlineIcon />
                    },
                    {
                        text: 'Помощь',
                        to: '/dashboard/tutorials',
                        icon: <HelpOutlineIcon />
                    }
                ]
            ],
            director: [
                [
                    {
                        text: 'Медиатека',
                        to: '/dashboard/media',
                        icon: <PermMediaIcon />
                    },
                    {
                        text: 'Турниры',
                        to: '/dashboard/tournaments',
                        icon: <SportsTennisIcon />
                    },
                    {
                        text: 'Работа с заявками',
                        to: '/dashboard/applications',
                        icon: <GroupAddIcon />
                    }
                ],
                [
                    {
                        text: 'Продвижение',
                        to: '/dashboard/promotion',
                        icon: <TrendingUpIcon />
                    }
                ],
                [
                    {
                        text: 'Помощь',
                        to: '/dashboard/tutorials',
                        icon: <HelpOutlineIcon />
                    }
                ]
            ]
            // moderator: [
            //     [
            //         {
            //             text: 'Публикации',
            //             to: '/dashboard/publications',
            //             icon: <PostAddIcon />
            //         },
            //         {
            //             text: 'Медиатека',
            //             to: '/dashboard/media',
            //             icon: <PermMediaIcon />
            //         },
            //         {
            //             text: 'Баннеры',
            //             to: '/dashboard/banners',
            //             icon: <ViewCarouselIcon />
            //         }
            //     ],
            //     [
            //         {
            //             text: 'Пользователи',
            //             to: '/dashboard/users',
            //             icon: <PeopleOutlineIcon />
            //         }
            //     ],
            //     [
            //         {
            //             text: 'Справочники',
            //             to: '/dashboard/references',
            //             icon: <HelpOutlineIcon />
            //         }
            //     ]
            // ],
            // judge: [
            //     [
            //         {
            //             text: 'Медиатека',
            //             to: '/dashboard/media',
            //             icon: <PermMediaIcon />
            //         },
            //         {
            //             text: 'Турниры',
            //             to: '/dashboard/tournaments',
            //             icon: <SportsTennisIcon />
            //         }
            //     ],
            //     [
            //         {
            //             text: 'Пользователи',
            //             to: '/dashboard/users',
            //             icon: <PeopleOutlineIcon />
            //         }
            //     ]
            // ]
        },
        roles: {
            admin: 'Администратор',
            director: 'Директор',
            // judge: 'Судья',
            // moderator: 'Модератор',
            player: 'Игрок',
            visitor: 'Посетитель'
        },
        rolesIcons: {
            admin: <SupervisorAccountIcon />,
            director: <FaceIcon />,
            judge: <SportsIcon />,
            moderator: <GavelIcon />,
            player: <SportsTennisIcon />,
            visitor: <PermIdentityIcon />
        },
        rolesColors: {
            // admin: 'primary',
            // director: 'primary',
            // judge: 'primary',
            // moderator: 'primary',
            // player: 'primary',
            // visitor: 'primary'
            admin: purple[500],
            director: green[500],
            judge: orange[500],
            moderator: red[500],
            player: teal[500],
            visitor: blueGrey[500]
        },
        status: {
            rejected: 'Отказано',
            draft: 'На рассмотрении',
            main: 'Основной состав',
            reserve: 'Запасной состав'
        },
        statusSelectOptions: [
            {
                id: 'main',
                name: 'Основной',
                status: 'approved',
                team: 'main'
            },
            {
                id: 'reserve',
                name: 'Запасной',
                status: 'approved',
                team: 'reserve'
            },
            {
                id: 'rejected',
                name: 'Отказано',
                status: 'rejected',
                team: null
            }
        ],
        statusSelectRejectOption: [
            {
                id: 'rejected',
                name: 'Отказано',
                status: 'rejected',
                team: null
            }
        ],
        statusIcons: {
            rejected: <CanceledIcon />,
            draft: <ReviewIcon />,
            main: <SuccessIcon />,
            reserve: <SuccessIcon />
        },
        statusColors: {
            rejected: red[500],
            draft: indigo[500],
            main: green[500],
            reserve: yellow[500]
            // rejected: 'primary',
            // draft: 'primary',
            // main: 'primary',
            // reserve: 'primary'
        },
        playerStatus: {
            veteran: 'Ветеран',
            pro: 'Профи',
            'semi-pro': 'Полупрофи',
            fan: 'Любитель'
        },
        playerStatusIcons: {
            veteran: <VeteranIcon />,
            pro: <Pro />,
            'semi-pro': <SemiPro />,
            fan: <FanIcon />
        },
        playerStatusColors: {
            // veteran: 'primary',
            // pro: 'primary',
            // 'semi-pro': 'primary',
            // fan: 'primary'
            veteran: cyan[500],
            pro: deepOrange[500],
            'semi-pro': teal[500],
            fan: pink[500]
        }
    }
};
